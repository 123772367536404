.projects-page {
  color: white;
  background-color: #000; /* Assuming the background is black */
  padding-top: 70px; /* Adjust this value based on the height of your navigation bar */
  min-height: 100vh; /* Minimum height to fill the screen */
}

.projects-menu {
  padding: 2rem; /* Add some spacing */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out the image and buttons */
}

.projects-menu .img-fluid {
  flex-grow: 1;
  margin-bottom: 2rem; /* Add space between image and buttons */
}

.projects-menu .img-fluid, .projects-info .img-fluid {
  max-height: 40vh; /* Lower the value to reduce the size */
  max-width: 100%; /* Adjust width as necessary, 100% means it will take the full width of its container */
  height: auto;
  object-fit: cover; /* This will ensure the image covers the assigned area and isn't stretched */
  
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center buttons horizontally */
  text-align: center; /* Center-align the buttons */
}


.projects-menu .btn-link {
  display: inline-block; /* Allows centering and border definition */
  color: white;
  font-size: 1.25rem; /* Large font size for readability */
  text-transform: uppercase; /* Uppercase letters as seen on the slide */
  margin-bottom: 1rem; /* Space between buttons */
  padding: 0.5rem 1rem; /* Padding for touch targets */
  border: none; /* Remove button borders */
  background-color: transparent; /* No background */
  transition: color 0.3s ease; /* Transition for hover effect */
  width: 100%; /* Adjust width as necessary */
  border-bottom: 3px solid white; /* White line under the button */
}

.projects-menu .btn-link:hover {
  color: #ccc; /* Lighten color on hover */
  text-decoration: none; /* No underline on hover */
}

.projects-menu .btn-link:last-child {
  border-bottom: none;
}

.projects-info {
  background-color: white;
  color: #000; /* Text color */
  padding: 2rem; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  text-align: left;
}
.images-content {
  text-align: center; /* Centers inline or inline-block elements horizontally */
}

.images-content .img-fluid {
  /* Ensure max-width is less than 100% if you want to center the image within the column */
  max-width: 90%; /* Adjust this percentage based on your design */
  height: auto; /* Maintain the aspect ratio of the image */
  object-fit: cover; /* Ensures the image covers the element's box, might not be necessary for your use case */
  margin: auto; /* This will center the image if it's a block-level element with a specified width */
}

.projects-info h2 {
  font-size: 4rem;
  text-align: left; /* Centering the title */
  margin-bottom: 2rem; /* Space below the title */
  border-bottom: 2px solid black;
  margin-bottom: 1.25em;
}

.projects-info .img-fluid {
  margin-bottom: 2rem; /* Space after the image */
   max-width: 90%; /* Adjust this percentage based on your design */
  height: auto; /* Maintain the aspect ratio of the image */
  object-fit: cover; /* Ensures the image covers the element's box, might not be necessary for your use case */
  margin: auto; /* This will center the image if it's a block-level element with a specified width */
}

.projects-info p {
  font-size: 1rem; 
  margin-bottom: 1rem; /* Space between paragraphs */
}

.text-content ul {
  list-style: inside; /* Puts the bullet points inside the content flow */
  padding-left: 0; /* Removes default padding */
}

.text-content li {
  margin-bottom: 0.5rem; /* Space between list items if needed */
}

@media (max-width: 768px) {
  .projects-menu, .projects-info {
    padding: 1rem;
  }

  .projects-menu .img-fluid {
    margin-bottom: 1rem;
  }

  .projects-info h2 {
    font-size: 3rem;
  }
}
