.gallery-container {
    padding-top: 70px; /* Adjust this value based on the height of your navigation bar */
  }
  .gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: black;
    min-height: 100vh;
    padding-bottom: 20px;
}

.thumbnails-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.thumbnail {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.thumbnail:hover {
    transform: scale(1.1);
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure lightbox is on top */
}

.lightbox-content {
  max-width: 70vw; /* Adjust the width of the lightbox content */
  max-height: 70vh; /* Adjust the height of the lightbox content */
  padding-top: 70px; /* Add padding top to avoid navbar */
  display: flex; /* Use Flexbox to center the image */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

