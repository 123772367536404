/* Enter transition */
.slide-enter {
    transform: translateX(-100%); /* Start from the left */
  }
  .slide-enter-active {
    transform: translateX(0); /* Move to the center */
    transition: transform 300ms ease-in-out;
  }
  
  /* Exit transition */
  .slide-exit {
    transform: translateX(0); /* Start from the center */
  }
  .slide-exit-active {
    transform: translateX(100%); /* Move to the right */
    transition: transform 300ms ease-in-out;
  }
  
