.profile-header {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    display: block;
    padding-top: 100px;
    margin-top: -70px; /* Adjust based on your nav height to fit under the navbar */
  }
  
  .about-me {
    padding-top: 20px; /* Adjust padding as necessary after the profile image */
    background-color: white;
  }
  
  .about-me h1, .about-me h2 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    
  }
  
  .about-me p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .about-me h1, .about-me h2 {
      font-size: 2.5rem;
    }
  }
  