
.bridal-occasion {
    padding-top: 70px; /* Adjust this value based on the height of your navigation bar */
    background-color: white;
  }

.bridal-occasion h1 {
    font-size: 4rem; /* Adjust the size as necessary */
    font-weight: bold;
    margin-bottom: 1rem; /* Adds some space below the title */
  }
  
  .bridal-occasion p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  /* This will ensure that the h2 stands out as a separate section title */
  .bridal-occasion h2 {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase; /* If you want to mimic the uppercase style */
    margin-bottom: 1rem; /* Adds some space below the subtitle */
  }
  
  /* Styling for the images and text in the bottom row */
  .bridal-occasion .text-center {
    margin-bottom: 2rem; /* Adds some space below each image section */
    
  }
  
  .bridal-occasion h3 {
    font-size: 2rem;
    margin-top: 1rem; /* Adds space between the image and the subheading */
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    font-style: italic;
    
  }

  .booking-option {
    border: 2px solid #ccc; /* Replace with the color matching your design */
    padding: 1rem; /* Adjust padding to match your design */
    margin-bottom: 1rem; /* Adds space between the booking options if stacked */
    height: 17rem;
    width: 40%;
    margin: 1rem;
    
  }
  
  .booking-option h3 {
    font-size: 1.5rem; /* Adjust the size as necessary */
    margin-bottom: 0.5rem; /* Space between the title and the text */
  }
  
  .booking-option p {
    font-size: 1rem; /* Adjust text size as necessary */
  }
 
  /* Styling for the 'BOOK WITH US FOR...' title */
  h2 {
    text-align: center; /* Center align the title */
    margin-bottom: 2rem; /* Space below the title */
  }
  .gallery{
    background-color: black;
    color: white;
    
  }
  
.gallery img {
  max-width: 100%; /* Ensure images don't exceed the width of their container */
  height: auto; /* Allow the height to adjust based on the aspect ratio */
  object-fit: contain; /* Maintain aspect ratio while fitting within the container */
}

  .page-navigation {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
  }
  
  .nav-button {
    font-size: 1.2rem; /* Adjust the size as necessary */
    color: black; /* Or your preferred color */
    transition: color 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .nav-button:hover {
    color: #555; /* Darken color on hover */
  }
  
  .prev-button {
    float: left;
  }
  
  .next-button {
    float: right;
  }
  
  