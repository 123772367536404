@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
body {
  margin: 0;
   font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
