.titlereview {
  margin-top: 6vh;
  color: white !important;
  font-size: 5vw; /* Default font size for mobile */
  letter-spacing: 0.3em;
  font-family: 'Playfair Display', serif;
  margin-bottom: 20px;
}

.card {
  background-color: #f8f9fa;
  border: none;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-subtitle {
  font-size: 1.1rem;
}

.card-text {
  font-size: 1.1rem;
}

button {
  margin: 5px;
}



.review-image {
  max-width: 40%;
  margin: 20px;
  border-radius: 10px;
}
.card-title-custom {
  font-weight: bold;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .titlereview {
    font-size: 3vw; /* Smaller font size for larger screens */
  }
}
