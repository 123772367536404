@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

/* Define the font sizes for different screen sizes */
.homepage {
    height: 100vh;
    background-size: cover;
    background-position: center;
    color: white;
    position: relative;
}

/* Styles for background video */
.background-video {
    position: fixed; /* or absolute, depending on other content */
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: -1;
}

/* Container for title and subtitle */
.title-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Title style */
.title {
    color: pink;
    font-size: 10vw; /* Default font size for mobile */
    letter-spacing: 0.3em;
    font-family: 'Playfair Display', serif;
}

/* Subtitle style */
.subtitle {
    font-size: 6vw; /* Default font size for mobile */
    margin: 2em;
    font-family: 'Playfair Display', serif;
}

/* Logo style */
.logo {
    margin-top: 1em;
}

/* Navbar style */
.navbar {
    border-bottom: 1px solid #fff;
    position: absolute;
    width: 100%;
}

/* Nav link style */
.nav-link {
    color: white;
    margin: 0 1em;
}

/* Hover effect for nav links */
.nav-link:hover {
    color: #ccc;
}

/* Lips logo style */
.lips-logo {
    width: 120px; /* You can adjust this size as needed */
    height: auto;
    margin-top: 0.5rem; /* Adjust spacing as needed */
}

/* Social links style */
.social-links {
    margin-top: 20px; /* Space above the social icons */
    display: flex;
    justify-content: center; /* Center the icons horizontally */
    gap: 120px; /* Space between each icon */
}

/* Social link style */
.social-links a {
    color: white; /* Set icon color to white */
    transition: color 0.3s ease; /* Smooth transition for hover effects */
}

/* Hover effect for social links */
.social-links a:hover {
    color: white; /* Keep icon color white on hover */
}

/* Media query for desktop screens */
@media (min-width: 768px) {
    .title {
        font-size: 5vw; /* Adjust font size for desktop */
    }

    .subtitle {
        font-size: 2.5vw; /* Adjust font size for desktop */
    }
}
