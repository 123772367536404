.booking-container {
  max-width: 600px;
  margin: 50px auto; /* Centers the form with margin from the top */
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border: 1px solid #ccc; /* Adds a subtle border */
  height: auto;
}

/* General styling for all form inputs and textarea for consistency */
.form-control {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 16px; /* Increase font size for better readability */
  padding: 10px; /* Padding inside the input fields */
}

/* Styling for labels */
.form-label {
  margin-bottom: 8px;
  color: #000000; /* Dark grey for better readability */
  display: block; /* Ensures the label is on its own line */
}

/* Button styling */
.btn-primary {
  background-color: black;;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: rgb(22, 22, 22);
  color: white;
}

.booking-container .mb-3 .form-control[type="date"]::-webkit-calendar-picker-indicator {
  background-color: white; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* Remove button border */
  padding: 5px; /* Adjust button padding */
  border-radius: 5px; /* Optional: Add border radius */
  cursor: pointer;
}

.booking-container .mb-3 .form-control[type="date"]::-webkit-inner-spin-button {
  display: none; /* Hide the inner spin button */
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .booking-container {
    padding: 20px;
    margin: 20px auto;
  }
}
