/* Base styles for the training section */
.my-training {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  font-family: 'Your font', sans-serif; /* Replace with your actual font */
  padding-top: 90px; /* Adjust based on your header/nav bar */
  padding-bottom: 70px; /* Spacing at the bottom */
}

/* Images should be responsive and maintain their aspect ratio */
.profile-image,
.content-image {
  width: 100%; /* Full width of the column */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Spacing below images */
}

/* Styles for the left column */
.training-left {
  padding: 0; /* Remove padding for full width use */
  /* The height will be auto-adjusted based on content */
}

/* The top image in the left column */
.top-profile-image {
  max-width: 100%; /* Full width of the column */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 40px; /* Spacing below the image */
}

/* Course list and items styling */
.course-list {
  padding-top: 20px; /* Padding at the top of the course list */
}

.course-item {
  text-align: center; /* Center align items */
  margin-bottom: 15px; /* Spacing between course items */
}

.course-item h3 {
  font-size: 1.5rem; /* Font size for mobile and scaling up for desktop */
  font-weight: bold; /* Bold font weight */
}

.course-item hr {
  border: none;
  height: 1px;
  background-color: #fff; /* White horizontal rule */
}



/* Title styling */
.training-title h2 {
  font-size: 2rem; /* Title font size */
  margin-bottom: 1rem; /* Spacing below title */
  text-align: center; /* Center-align title */
}

/* Text content styling */
.training-text {
  padding: 15px; /* Padding inside the text container */
}



/* Media query for larger screens */
@media (min-width: 768px) {
  .course-item h3 {
    font-size: 2rem; /* Increase font size on larger screens */
  }

  /* If you have additional specific styles for larger screens, add them here */
}
