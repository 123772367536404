.editorial-fashion {
    padding-top: 70px; /* Adjust this value based on the height of your navigation bar */
    background-color: white;
  }
  
  .editorial-fashion h1 {
    font-size: 4rem; /* Adjust the size as necessary */
    font-weight: bold;
    margin-bottom: 1rem; /* Adds some space below the title */
  }
  
  .editorial-fashion p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  /* This will ensure that the h2 stands out as a separate section title */
  .editorial-fashion h2 {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase; /* If you want to mimic the uppercase style */
    margin-bottom: 1rem; /* Adds some space below the subtitle */
  }
  
  /* Styling for the images and text in the bottom row */
  .editorial-fashion .text-center {
    margin-bottom: 2rem; /* Adds some space below each image section */
  }
  
  .editorial-fashion h3 {
    font-size: 2rem;
    margin-top: 1rem; /* Adds space between the image and the subheading */
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    font-style: italic;
  }
  
  .editorial-fashion .booking-option {
    border: 2px solid #ccc; /* Replace with the color matching your design */
    padding: 1rem; /* Adjust padding to match your design */
    margin-bottom: 1rem; /* Adds space between the booking options if stacked */
    height: 17rem;
    width: 40%;
    margin: 1rem;
  }
  
  .editorial-fashion .booking-option h3 {
    font-size: 1.5rem; /* Adjust the size as necessary */
    margin-bottom: 0.5rem; /* Space between the title and the text */
  }
  
  .editorial-fashion .booking-option p {
    font-size: 1rem; /* Adjust text size as necessary */
  }
  
  /* Styling for the 'BOOK WITH US FOR...' title */
  .editorial-fashion h2 {
    text-align: center; /* Center align the title */
    margin-bottom: 2rem; /* Space below the title */
  }
  
  .editorial-fashion .gallery {
    background-color: black;
    color: white;
  }
  